import { useEffect, useState } from 'react';
import { useWriteRspContractJoinRoom } from 'shared/features/contract.abi';
import { useRoomStore } from 'shared/store/roomStore';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { ModalTypeEnum } from 'modules/Room/RoomModals/constants/modalEnums';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useAccount } from 'wagmi';
import { WriteError } from 'shared/types/app-types';
import * as Sentry from '@sentry/react';

const useJoinGame = () => {
  const { chainId } = useAccount();
  const [localHash, setLocalHash] = useState<`0x${string}`>();
  const { setJoinHash, joinHash } = usePlayerGamesStore();
  const { joinRoomId, setIsGameSearching, setJoinRoomId } = useRoomStore();
  const { setModalState, closeAllModals, setGameBetModalState } =
    useModalStore();
  const { setTransactionCallBack } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const {
    writeContractAsync,
    error,
    isSuccess,
    data: joinRoomData,
  } = useWriteRspContractJoinRoom();
  useEffect(() => {
    if (chainId && joinHash[chainId]?.hash)
      setLocalHash(joinHash[chainId]?.hash);
  }, []);
  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('user approved join', timeString);
      setJoinHash(chainId!, joinRoomData!, now);
      setLocalHash(joinRoomData!);
    }
  }, [isSuccess]);
  const {
    isSuccess: isSuccessJoin,
    isError,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(localHash, joinHash?.[chainId!]?.date);

  useEffect(() => {
    if (limitExceed){
      console.log('limit exceed useJoinGame');
      setModalState(ModalTypeEnum.TRANSACTION_TAKE_TO_LONG, true);
    }
  }, [limitExceed]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccessJoin && joinRoomId) {
      if (!activeRoomId) {
        navigate(`${ROUTES.ROOM}/${Number(joinRoomId)}`);
      }
      closeAllModals();
      setGameBetModalState({ reject: false, error: false });
      setJoinHash(chainId!, null);
      setModalState(ModalTypeEnum.CONNECTING_TO_PLAYER, true);
      setIsGameSearching(false);
      setJoinRoomId(null);
    } else if (isSuccessJoin && !activeRoomId) {
      window.location.reload();
      setJoinHash(chainId!, null);
    }
  }, [isSuccessJoin]);

  useEffect(() => {
    if (error || isError || transactionError) {
      console.log('join room error');
      console.log(error);
      console.log(transactionError);
      if (error) {
        Sentry.captureException(error);
        const typedError = error as WriteError | undefined;
        if (typedError?.cause?.code) {
          setGameBetModalState({
            reject: typedError.cause.code === 4001,
            error: typedError.cause.code !== 4001,
          });
        } else {
          setGameBetModalState({
            reject: false,
            error: true,
          });
        }
      }
      if (transactionError) Sentry.captureException(transactionError);
      setJoinRoomId(null);
      setIsGameSearching(false);
      closeAllModals();
      setModalState(
        activeRoomId ? ModalTypeEnum.JOIN_GAME : ModalTypeEnum.SET_BET,
        true
      );
    }
  }, [error, isError, transactionError]);
  return async (args: any) => {
    try {
      await writeContractAsync(args);
      setTransactionCallBack((gas: bigint) => {
        writeContractAsync({ ...args, gas });
      });
    } catch (error) {
      console.log('writeContract error', error);
      closeAllModals();
      setGameBetModalState({ reject: false, error: true });
      setModalState(ModalTypeEnum.SET_BET, true);
    }
  };
};

export default useJoinGame;
