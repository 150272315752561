import { MovesEnum } from 'shared/constants/shared-enums';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type HashDateObject = {
  hash: `0x${string}`;
  date: Date;
};

interface State {
  playMoves: Record<string, PlayMove>;
  allowanceTransactions: {
    amount: number | null;
    hash: `0x${string}` | null;
    date: Date | null;
  };
  startHash: Record<string, HashDateObject | null>;
  joinHash: Record<string, HashDateObject | null>;
  noOneJoinHash: Record<string, HashDateObject | null>;
  closeHash: Record<string, HashDateObject | null>;
  transactionCallBack: null | ((gas: bigint) => void);
}

interface PlayMove {
  salt: string | null;
  move: MovesEnum;
  playerAddress: string;
  moveHash: HashDateObject | null;
  revealHash: HashDateObject | null;
}

interface Actions {
  setPlayMove: (key: string, arg0: PlayMove) => void;
  deletePlayMove: (key: string) => void;
  setMoveHash: (key: string, hash: `0x${string}`, date?: Date) => void;
  setRevealHash: (key: string, hash: `0x${string}` | null, date?: Date) => void;
  setAllowanceHash: (hash: `0x${string}` | null, date?: Date) => void;
  setAllowanceAmount: (amount: number) => void;
  setStartHash: (
    chainId: number,
    hash: `0x${string}` | null,
    date?: Date
  ) => void;
  setJoinHash: (
    chainId: number,
    hash: `0x${string}` | null,
    date?: Date
  ) => void;
  setNoOneJoinHash: (
    chainId: number,
    hash: `0x${string}` | null,
    date?: Date
  ) => void;
  setCloseHash: (
    chainId: number,
    hash: `0x${string}` | null,
    date?: Date
  ) => void;
  setTransactionCallBack: (callback: (gas: bigint) => void) => void | null;
}

const initialState: State = {
  playMoves: {},
  allowanceTransactions: {
    amount: null,
    hash: null,
    date: null,
  },
  startHash: {},
  joinHash: {},
  noOneJoinHash: {},
  closeHash: {},
  transactionCallBack: null,
};

export const usePlayerGamesStore = create<State & Actions>()(
  persist(
    immer((set) => ({
      ...initialState,
      setPlayMove: (key, playMove) =>
        set((state) => ({
          playMoves: {
            ...state.playMoves,
            [key]: playMove,
          },
        })),
      deletePlayMove: (key) =>
        set((state) => {
          Object.keys(state.playMoves).forEach((playMoveKey) => {
            if (playMoveKey.startsWith(key)) {
              delete state.playMoves[playMoveKey];
            }
          });
        }),
      setMoveHash: (key, hash, date) =>
        set((state) => {
          if (state.playMoves[key]) {
            if (hash) {
              state.playMoves[key].moveHash = {
                hash,
                date: date || new Date(),
              };
            } else {
              state.playMoves[key].moveHash = null;
            }
          }
        }),
      setRevealHash: (key, hash, date) =>
        set((state) => {
          if (state.playMoves[key]) {
            if (hash) {
              state.playMoves[key].revealHash = {
                hash,
                date: date || new Date(),
              };
            } else {
              state.playMoves[key].revealHash = null;
            }
          }
        }),
      setAllowanceHash: (hash, date) =>
        set((state) => {
          if (hash) state.allowanceTransactions.hash = hash;
        }),
      setAllowanceAmount: (amount) =>
        set((state) => {
          state.allowanceTransactions.amount = amount;
        }),
      setStartHash: (chainId, hash, date) =>
        set((state) => {
          if (hash) {
            state.startHash[chainId] = {
              hash,
              date: date || new Date(),
            };
          } else {
            state.startHash[chainId] = null;
          }
        }),
      setJoinHash: (chainId, hash, date) =>
        set((state) => {
          if (hash) {
            state.joinHash[chainId] = {
              hash,
              date: date || new Date(),
            };
          } else {
            state.joinHash[chainId] = null;
          }
        }),
      setNoOneJoinHash: (chainId, hash, date) =>
        set((state) => {
          if (hash) {
            state.noOneJoinHash[chainId] = {
              hash,
              date: date || new Date(),
            };
          } else {
            state.noOneJoinHash[chainId] = null;
          }
        }),
      setCloseHash: (chainId, hash, date) =>
        set((state) => {
          if (hash) {
            state.closeHash[chainId] = {
              hash,
              date: date || new Date(),
            };
          } else {
            state.closeHash[chainId] = null;
          }
        }),
      setTransactionCallBack: (callback) =>
        set((state) => {
          state.transactionCallBack = callback;
        }),
    })),
    { name: 'playerGamesStore' }
  )
);
