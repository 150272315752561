import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useParams } from 'react-router-dom';
import { useRoomStore } from 'shared/store/roomStore';
import { useAccount } from 'wagmi';
import { useReveal } from 'modules/Room/Hooks/useReveal';
import { useEffect } from 'react';
import {
  RoundStagesEnum,
  RoomStatusEnum,
  MovesEnum,
} from 'shared/constants/shared-enums';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import useMediaQuery from 'shared/hooks/useMediaQuery';

const useRevealLogic = () => {
  const { playMoves } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    roundStage,
    roundCounter,
    roomStatus,
    playerA,
    roundMoves,
    roomExpire,
  } = useRoomStore();
  const { address, chainId } = useAccount();
  const { revealModalState, modalState } = useModalStore();
  const { writeReveal } = useReveal();
  const amIPlayerA = playerA?.address.toLowerCase() === address?.toLowerCase();
  useEffect(() => {
    const isReadyForReveal =
      playMoves[
        activeRoomId! + address + chainId + roundCounter
      ]?.playerAddress?.toLowerCase() === address?.toLowerCase() &&
      roundStage === RoundStagesEnum.RevealMove &&
      roomStatus === RoomStatusEnum.ReadyForReveal;
    const isPlayerMoveNone =
      (amIPlayerA && roundMoves.playerA.move === MovesEnum.None) ||
      (!amIPlayerA && roundMoves.playerB.move === MovesEnum.None);
    const isRevealModalError = revealModalState.error;
    const isReadyForRevealCondition =
      (isReadyForReveal && isPlayerMoveNone) ||
      (isReadyForReveal && isRevealModalError && isPlayerMoveNone);
    if (
      isReadyForRevealCondition &&
      !playMoves[activeRoomId! + address + chainId + roundCounter].revealHash?.hash &&
      !roomExpire
    ) {
      setTimeout(
        () => {
          console.log('call reveal');
          writeReveal();
        },
        isMobile ? 4000 : 0
      );
    }
  }, [roundStage, playMoves, address, roundCounter, revealModalState]);
};

export default useRevealLogic;
