import { NULL_ADDRESS } from 'shared/constants/constants';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

interface State {
  isLoading: boolean;
  invitedBy: `0x${string}`;
  notFirstTime: boolean;
  activeGames: Record<string, string>;
  isMobileBannerClosed: boolean;
}

interface Actions {
  setIsLoading: (flag: boolean) => void;
  setInvited: (arg0: `0x${string}`) => void;
  setNotFirstTime: (flag: boolean) => void;
  addActiveGame: (key: string, id: string) => void;
  removeActiveGame: (key: string) => void;
  setIsMobileBannerClosed: (flag: boolean) => void;
}

const initialState: State = {
  isLoading: false,
  invitedBy: NULL_ADDRESS,
  notFirstTime: false,
  activeGames: {},
  isMobileBannerClosed: false,
};

export const useGlobalStore = create<State & Actions>()(
  persist(
    immer((set) => ({
      ...initialState,
      setIsLoading: (newFlag) =>
        set(() => ({
          isLoading: newFlag,
        })),
      setInvited: (address) =>
        set(() => ({
          invitedBy: address,
        })),
      setNotFirstTime: (flag) =>
        set(() => ({
          notFirstTime: flag,
        })),
      addActiveGame: (key, id) =>
        set((state) => ({
          activeGames: {
            ...state.activeGames,
            [key]: id,
          },
        })),
      setIsMobileBannerClosed: (flag) =>
        set(() => ({
          isMobileBannerClosed: flag,
        })),
      removeActiveGame: (key) =>
        set((state) => {
          delete state.activeGames[key];
        }),
    })),
    { name: 'globalStore' }
  )
);
