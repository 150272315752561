// Generate a random alphanumeric word
export const generateRandomWord = (): string => {
  const alphabet =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const minLength = 20;
  const maxLength = 25;
  const length =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  let word = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    word += alphabet[randomIndex];
  }
  return word;
};


// Case-insensitive string comparison
export const compareLowerCase = (a: string, b: string): boolean =>
  a.toLowerCase() === b.toLowerCase();









