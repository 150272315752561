import React, { useState, useRef, useEffect, useMemo } from 'react';
import Dropdown from 'shared/components/DropDown/DropDown';
import PrimaryButtonWithSvg from 'shared/components/PrimaryButton/PrimaryButtonWithSvg';
import BurgerIcon from 'assets/burger.svg?react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { useAccount, useDisconnect } from 'wagmi';
import { createPortal } from 'react-dom';
import { selectOption } from 'shared/components/Select/Select';
import { useSwitchChain } from 'wagmi';
import IconChain from 'shared/components/IconChain/IconChain';
import ArrowIcon from 'assets/arrow.svg?react';
import { useSwitchChainHandler } from 'modules/Room/Hooks/useSwitchHandler';
import MobileDropDown from 'modules/Room/mobile/MobileDropDown/MobileDropDown';
import LogoutIcon from 'assets/logout.svg?react';
import AboutIcon from 'assets/about.svg?react';
import { useTranslation } from 'react-i18next';

const RoomMenu = () => {
  const [networkOpen, setNetworkOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLButtonElement>(null);
  const chainRef = useRef<HTMLButtonElement>(null);
  const mobileDropdownRef = useRef<HTMLDivElement>(null);
  const { chains } = useSwitchChain();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const { chain, chainId } = useAccount();
  const { t } = useTranslation();

  const chainsList = useMemo(
    () =>
      chains.map((chain) => ({
        label: chain.name,
        value: chain.id,
      })),
    [chains]
  );

  const menuHandler = (option: selectOption) => {
    if (option.value === 'logout') {
      disconnect();
      navigate(ROUTES.HOME);
    } else if (typeof option.value === 'string') {
      window.open(option.value, '_blank');
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        mobileDropdownRef.current &&
        !mobileDropdownRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
      if (
        chainRef.current &&
        !chainRef.current.contains(event.target as Node)
      ) {
        setNetworkOpen(false);
      }
    };

    if (menuOpen || networkOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen, networkOpen]);
  const switchChainHandler = useSwitchChainHandler();

  return createPortal(
    <div className="flex bg-black bg-opacity-30 border border-t-0 border-border rounded-b-lg absolute top-0 left-1/2 transform -translate-x-1/2 py-[16px] px-[32px] z-50">
      <button
        onClick={() => setNetworkOpen(!networkOpen)}
        className="py-[5px] px-[18px] mr-[24px] border border-border rounded-[6px] flex items-center cursor-pointer"
        ref={chainRef}
      >
        {chain && <IconChain chainId={chain.id} className="mr-[10px]" />}
        <ArrowIcon
          className={`${
            networkOpen ? 'transform rotate-180' : ''
          } transition ease-in-out duration-500`}
        />
      </button>
      <PrimaryButtonWithSvg
        ref={menuRef}
        svg={<BurgerIcon className="w-[26px] h-[22px]" />}
        clickHandler={() => setMenuOpen(!menuOpen)}
      />

      <MobileDropDown
        open={menuOpen}
        ref={mobileDropdownRef}
        list={[
          {
            label: t('pages.about'),
            value: 'https://rock-paper-scissors.game/rules',
            svg: <AboutIcon className={'h-[20px] w-[20px] ml-[10px]'} />,
          },
          {
            label: t('pages.referral'),
            value: 'https://rock-paper-scissors.game/referral',
            svg: null,
          },
          {
            label: 'Logout',
            value: 'logout',
            svg: <LogoutIcon className="h-[25px] w-[25px] ml-[10px] " />,
          },
        ]}
        onClick={menuHandler}
        position={{ top: 82, left: 0 }}
      />
      <Dropdown
        open={networkOpen}
        list={chainsList}
        onClick={switchChainHandler}
        active={chainId}
        position={{ top: 82, left: 0 }}
      />
    </div>,
    document.body
  );
};

export default RoomMenu;
