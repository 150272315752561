import { useReadRspContractRounds } from 'shared/features/contract.abi';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useRoomStore } from 'shared/store/roomStore';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import * as Sentry from '@sentry/react';

export const useRoundInfo = () => {
  const { activeRoomId } = useParams();
  const {
    roundCounter,
    closerRoom,
    setRoundStage,
    setRoundMoves,
    roomStatus,
  } = useRoomStore();
  const { data, refetch, isFetching } = useReadRspContractRounds({
    args: [BigInt(activeRoomId ? activeRoomId : 0), BigInt(roundCounter)],
    query: {
      enabled: !!activeRoomId,
    },
  });
  useEffect(() => {
    if (data && activeRoomId) {
      const [moveA, moveB, encrMoveA, encrMoveB, winner, stage, roundStage] =
        data;
      setRoundStage(roundStage);
      Sentry.setTag('roundStage', roundStage);
      setRoundMoves({
        playerA: {
          encrMove: encrMoveA,
          move: moveA,
        },
        playerB: {
          encrMove: encrMoveB,
          move: moveB,
        },
      });
    }
  }, [data]);
  useEffect(() => {
    if (roomStatus !== RoomStatusEnum.Closed) {
      refetch();
    }
  }, [closerRoom, roomStatus]);
};
