import { useRoomStore } from 'shared/store/roomStore';
import { PlayerCard } from 'modules/Room/features/PlayerCard/PlayerCard';
import HistoryMoves from 'modules/Room/features/HistoryMoves/HistoryMoves';
import Score from 'modules/Room/features/Score/Score';
import Bank from 'shared/components/Bank/Bank';
import RoomMenu from 'modules/Room/features/RoomMenu/RoomMenu';
import { useParams } from 'react-router-dom';
import useCreateJoinRoomEvents from 'modules/Room/Hooks/useCreateJoinRoomEvents';

const DesktopRoom = () => {
  useCreateJoinRoomEvents();
  const { activeRoomId } = useParams();
  const { playerA, playerB, amIPlayerA, someoneElseGame } = useRoomStore();
  const classNamePlayerACard = someoneElseGame
    ? 'order-1'
    : amIPlayerA
    ? 'order-1'
    : 'order-3';
  const classNamePlayerBCard = someoneElseGame
    ? 'order-3'
    : amIPlayerA
    ? 'order-3'
    : 'order-1';
  return (
    <>
      <div className="grid grid-cols-1 place-items-center h-screen overflow-auto">
        <RoomMenu />
        <div className="flex flex-col sm:flex-row items-stretch justify-between max-w-[1940px] max-h-[1080px] w-full h-full px-[70px] py-[95px]">
          <div
            className={`${classNamePlayerACard} sm:max-w-[630px] sm:max-h-[890px] sm:w-[35%] w-full`}
          >
            <PlayerCard playerAddress={playerA.address} />
          </div>
          <div className="flex flex-col sm:w-[20%] w-full h-full sm:max-w-[350px] sm:min-w-[280px] justify-between order-2 pt-[20px] 2xl:pt-[100px]">
            <Score />
            <HistoryMoves />
            <Bank />
          </div>
          <div
            className={`${classNamePlayerBCard}  sm:max-w-[630px] sm:w-[35%] sm:max-h-[890px] w-full`}
          >
            <PlayerCard playerAddress={playerB.address} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopRoom;
