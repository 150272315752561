import React, { useEffect } from 'react';
import { useAccount } from 'wagmi';
import MobileRoom from 'modules/Room/pages/MobileRoom';
import DesktopRoom from 'modules/Room/pages/DesktopRoom';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { CHAINS_IDS } from 'shared/config';
import { ROUTES } from 'shared/constants';
import { useActiveGameRedirect } from 'modules/Room/Hooks/useActiveGameRedirect';
import { useActiveRoom } from 'modules/Room/Hooks/useActiveRoom';
import { useFindGames } from 'modules/Room/Hooks/useFindGames';
import { RoomModals } from 'modules/Room/RoomModals';

export const Room = () => {
  const { isConnected, chainId, address } = useAccount();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const navigate = useNavigate();
  useEffect(() => {
    if (!isConnected) {
      navigate(ROUTES.HOME);
    }
  }, [isConnected]);
  useEffect(() => {
    if (chainId && !CHAINS_IDS.includes(chainId as 1 | 42161 | 137 | 8453)) {
      navigate(ROUTES.HOME);
    }
  }, [chainId, navigate]);
  useActiveGameRedirect();
  useActiveRoom();
  useFindGames();
  if (!isConnected) return null;
  return (
    <>
      <RoomModals />
      {isMobile ? <MobileRoom /> : <DesktopRoom />}
    </>
  );
};
