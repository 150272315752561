import { paper, rock, scissors } from 'assets';
import { ethers } from 'ethers';
import { formatEther, formatUnits, parseUnits } from 'viem';

const regexpValidations = {
  allowOnlyDigits: (v: string) => v.replace(/[^0-9.,]/g, ''),
  removeLeadingZeroBeforeNonZero: (v: string) => v.replace(/^0+(?=\d)/, ''),
  removeLeadingZeros: (v: string) => v.replace(/^0+(?=[1-9])/, ''),
  replaceCommas: (v: string) => v.replace(/,/g, '.'),
  replaceDotAndCommaAtStart: (v: string) => v.replace(/^[.,]/, ''),
};

const allowCountNumberAfterDot = (value: string, count = 5) => {
  const decimalIndex = value.indexOf('.');
  return decimalIndex !== -1 ? value.slice(0, decimalIndex + count) : value;
};

const disallowDotAtTheEnd = (value: string) => {
  return (value.match(/[.,]/g) || []).length > 1
    ? value.replace(/[.,]$/g, '')
    : value;
};

// Utility function to shorten Ethereum addresses
export const shortenAddress = (
  address: string = '',
  symbols: number = 5
): string => `${address.slice(0, symbols)}...${address.slice(-4)}`;

// Immutable game moves configuration
export const Moves = Object.freeze({
  NONE: { label: 'none', value: 0 },
  PAPER: { label: 'common.paper', value: 2 },
  ROCK: { label: 'common.rock', value: 1 },
  SCISSORS: { label: 'common.scissors', value: 3 },
});

// List of game moves with associated icons
export const moveList = [
  { icon: rock, label: Moves.ROCK.label, value: Moves.ROCK.value },
  { icon: paper, label: Moves.PAPER.label, value: Moves.PAPER.value },
  { icon: scissors, label: Moves.SCISSORS.label, value: Moves.SCISSORS.value },
];

// Game stages configuration
export const Stages = {
  DONE: { label: 'Finished', value: 6 },
  DRAW: { label: 'Draw', value: 4 },
  DRAWWAITFOROPPONENT: { label: 'Draw Wait For Opponent', value: 5 },
  NONE: { label: 'None', value: 0 },
  READYFORFINALIZE: { label: 'Ready For Finalize', value: 3 },
  WAITFOROPPONENT: { label: 'Wait For Opponent', value: 1 },
  WAITFORREVEAL: { label: 'Wait For Reveal', value: 2 },
} as const;

// Convert BigNumber to number
export const convertBigNumberToNumber = (bigNumber: bigint): number =>
  Number(formatEther(bigNumber));

// Convert token numeric value to number with specific exponent
export const convertTokenNumberToNumber = (
  value: bigint,
  exponent: number
): number => Number(formatUnits(value, exponent));

// Convert numeric value to token unit based on exponent
export const convertNumberToTokenNumber = (value: number, exponent: number) =>
  parseUnits(value.toString(), exponent);

// Generate a random alphanumeric word
export const generateRandomWord = (): string => {
  const alphabet =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const minLength = 20;
  const maxLength = 25;
  const length =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  let word = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    word += alphabet[randomIndex];
  }
  return word;
};

// Generate a hidden move using keccak256
export const generateHiddenMove = ({
  move,
  pass,
  walletAddress,
}: {
  move: number;
  pass: string;
  walletAddress: string;
}): `0x${string}` => {
  if (!pass || !move || !walletAddress) {
    throw new Error(
      `Invalid arguments: pass=${pass}, move=${move}, walletAddress=${walletAddress}`
    );
  }
  return ethers.utils.solidityKeccak256(
    ['string', 'uint8', 'address'],
    [pass, move, walletAddress]
  ) as `0x${string}`;
};

// Convert hex string to number
export const convertHexToNumber = (hex: string): number => parseInt(hex, 16);

// Case-insensitive string comparison
export const compareLowerCase = (a: string, b: string): boolean =>
  a.toLowerCase() === b.toLowerCase();

// Generic sorting function
export const getSort = <T extends Record<string, any>>(
  data: T[],
  field: string,
  isAsc: boolean
): T[] =>
  data.sort((a, b) => (isAsc ? a[field] - b[field] : b[field] - a[field]));

// Alphabetical sorting function for objects
export const getSortAlphabetically = <
  T extends Record<string, string | number | boolean | number[]>
>(
  data: T[],
  field: keyof T,
  isAsc?: boolean
): T[] =>
  data.sort((a, b) =>
    isAsc
      ? String(a[field]).localeCompare(String(b[field]))
      : String(b[field]).localeCompare(String(a[field]))
  );

// Debounce function to limit the rate at which a function can fire
export const debounce = (
  func: (...args: any[]) => void,
  timeout: number = 100
) => {
  let timer: NodeJS.Timeout;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

// Pluralization utility
export const pluralize = (
  count: number,
  noun: string,
  suffix: string = 's'
): string => `${count !== 1 ? noun + suffix : noun}`;

// Mobile device detection
export const mobileCheck = (): boolean => {
  const userAgent = navigator.userAgent;
  return /android|iphone|ipad|ipod|opera mini|iemobile|wpdesktop/i.test(
    userAgent
  );
};

// Validate and format input value using regular expressions
export const prepareRegexpValidation = (value: string): string => {
  const newValue = Object.values(regexpValidations).reduce(
    (acc, regex) => regex(acc),
    value
  );
  return disallowDotAtTheEnd(allowCountNumberAfterDot(newValue));
};

// Simple timeout promise for asynchronous delays
export const timeout = (delay: number = 500): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, delay));

// Wait for a promise to resolve with a minimum delay
export const wait = async (
  promise: Promise<any>,
  minDelay: number
): Promise<any[]> => Promise.all([promise, timeout(minDelay)]);

// Check if a given hex string exists
export const isHexExists = (hex: string): boolean => !!hex;
