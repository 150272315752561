import { useCallback, useEffect, useRef, useState } from 'react';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useWriteRspContractReveal } from 'shared/features/contract.abi';
import { useAccount } from 'wagmi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { useParams } from 'react-router-dom';
import { useRoomStore } from 'shared/store/roomStore';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { RoomStatusEnum, RoundStagesEnum } from 'shared/constants/shared-enums';
import { WriteError } from 'shared/types/app-types';
import * as Sentry from '@sentry/react';
import { ModalTypeEnum } from 'modules/Room/RoomModals/constants/modalEnums';

export const useReveal = () => {
  const [revealHashLocal, setRevealHashLocal] = useState<
    `0x${string}` | undefined
  >();
  const { playMoves } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const { roundCounter, refetchRoom, roundStage, roomStatus } = useRoomStore();
  const { setRevealModalStateError } = useModalStore();
  const { setRevealHash } = usePlayerGamesStore();
  const placingReveal = useRef(false);
  const { setModalState } = useModalStore();
  const { setTransactionCallBack } = usePlayerGamesStore();

  const {
    data,
    writeContractAsync,
    error: revealError,
    isSuccess,
  } = useWriteRspContractReveal();

  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('user approved reveal', timeString);
      setRevealHash(
        activeRoomId! + address! + chainId + roundCounter,
        data!,
        now
      );
      setRevealHashLocal(data);
      refetchRoom();
    }
  }, [isSuccess]);
  useEffect(() => {
    if (
      !revealHashLocal &&
      roundStage === RoundStagesEnum.RevealMove &&
      roomStatus === RoomStatusEnum.ReadyForReveal
    ) {
      if (
        playMoves[activeRoomId! + address! + chainId + roundCounter]?.revealHash
          ?.hash
      ) {
        setRevealHashLocal(
          playMoves[activeRoomId! + address! + chainId + roundCounter]
            .revealHash!.hash
        );
      }
    }
  }, [revealError]);

  const {
    isSuccess: isSuccessReveal,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(
    revealHashLocal,
    playMoves?.[activeRoomId! + address + chainId + roundCounter]?.revealHash
      ?.date
  );
  useEffect(() => {
    if (limitExceed)
      setModalState(ModalTypeEnum.TRANSACTION_TAKE_TO_LONG, true);
  }, [limitExceed]);

  useEffect(() => {
    if (isSuccessReveal) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('success reveal', timeString);
      refetchRoom();
      setRevealModalStateError({ error: false, reject: false });
      placingReveal.current = false;
    }
  }, [isSuccessReveal, roundCounter]);

  useEffect(() => {
    if (revealError || transactionError) {
      console.log('reveal error');
      if (revealError) {
        Sentry.captureException(revealError);
        const typedError = revealError as WriteError | undefined;
        if (typedError?.cause?.code) {
          setRevealModalStateError({
            reject: typedError.cause.code === 4001,
            error: typedError.cause.code !== 4001,
          });
        } else {
          setRevealModalStateError({
            reject: false,
            error: true,
          });
        }
      }
      setRevealHashLocal(undefined);
      setRevealHash(activeRoomId! + address! + chainId + roundCounter, null);
      if (transactionError) Sentry.captureException(transactionError);
      placingReveal.current = false;
    }
  }, [revealError, transactionError]);

  const writeReveal = useCallback(async () => {
    if (placingReveal.current) return;
    try {
      placingReveal.current = true;
      setTransactionCallBack((gas: bigint) => {
        writeContractAsync({
          args: [
            BigInt(activeRoomId || 0n),
            roundCounter || 0,
            playMoves?.[activeRoomId! + address + chainId + roundCounter].move,
            playMoves?.[activeRoomId! + address + chainId + roundCounter]
              .salt as string,
          ],
          gas: 271000n,
        });
      });
      await writeContractAsync({
        args: [
          BigInt(activeRoomId || 0n),
          roundCounter || 0,
          playMoves?.[activeRoomId! + address + chainId + roundCounter].move,
          playMoves?.[activeRoomId! + address + chainId + roundCounter]
            .salt as string,
        ],
        gas: 271000n,
      });
    } catch (error) {
      placingReveal.current = false;
      console.log(error);
    }
  }, [
    activeRoomId,
    playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move,
    playMoves?.[activeRoomId! + address + chainId + roundCounter]?.salt,
    writeContractAsync,
  ]);

  return { writeReveal };
};
