import { useEffect, useState, useRef } from 'react';
import { useWaitForTransactionReceipt } from 'wagmi';
import { TIME_FOR_TRANSACTION_MINTING } from 'shared/constants/timers';

export const useTransactionReceipt = (txHash?: `0x${string}`, date?: Date) => {
  const [limitExceed, setLimitExceed] = useState(false);
  const { data, isSuccess, isError, error } = useWaitForTransactionReceipt({
    hash: txHash,
  });
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (date && txHash && !(isSuccess || isError)) {
      const now = new Date();
      const timePassed = now.getTime() - new Date(date).getTime();
      const timeRemaining = TIME_FOR_TRANSACTION_MINTING - timePassed;

      if (timeRemaining > 0) {
        timerRef.current = setTimeout(() => {
          setLimitExceed(true);
        }, timeRemaining);
      } else {
        setLimitExceed(true);
      }
    } else if (txHash && !(isSuccess || isError)) {
      timerRef.current = setTimeout(() => {
        setLimitExceed(true);
      }, TIME_FOR_TRANSACTION_MINTING);
    }
    if (isSuccess || isError) {
      if (timerRef.current) clearTimeout(timerRef.current);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [date, txHash, isSuccess, isError]);

  return { data, isSuccess, isError, error, limitExceed };
};
