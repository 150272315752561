import HistoryMove from './HistoryMove';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './HistoryMoves.scss';
import { useRoomStore } from 'shared/store/roomStore';
import { useGetHistoryMoves } from 'modules/Room/Hooks/useGetHistoryMoves';
import { useParams } from 'react-router-dom';
import { RoomStatusEnum } from 'shared/constants/shared-enums';

const HistoryMoves = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const { roundCounter } = useRoomStore();
  const gameHistory = useGetHistoryMoves();
  const { activeRoomId } = useParams();
  const { roomStatus } = useRoomStore();
  const [lastRoundIndex, setLastRoundIndex] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (roundCounter) {
        if (screenWidth >= 1920 && roundCounter > 5) {
          setHasScrollbar(true);
        } else if (roundCounter >= 4) {
          setHasScrollbar(true);
        } else {
          setHasScrollbar(false);
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [roundCounter]);
  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      setLastRoundIndex(0);
    } else {
      setLastRoundIndex(1);
    }
  }, [roomStatus]);
  return (
    <div className="flex flex-col items-center">
      <h4 className="text-blue-text font-xl font-medium mb-2">
        {t('common.history')}
      </h4>
      <div className="border border-dark-border rounded-[7px] w-full bg-dark-blue">
        <div
          style={{ background: 'rgba(255, 255, 255, 0.03)' }}
          className="flex w-full rounded-t-[7px] py-[10px] uppercase text-[14px] text-gray font-light mb-[14px]"
        >
          <span className="w-[33%] flex justify-center">{t('common.you')}</span>
          <span className="w-[33%] flex justify-center">
            {t('common.round')}
          </span>
          <span className="w-[33%] flex justify-center">
            {t('common.opponent')}
          </span>
        </div>
        <div
          ref={containerRef}
          className={`sm:h-[300px] hd:h-[450px] overflow-auto history ${
            hasScrollbar ? 'mr-[7px]' : ''
          }`}
        >
          {activeRoomId &&
            gameHistory.map((round, index) => (
                <HistoryMove
                  key={round.encrMoveA + index}
                  round={round}
                  gameCount={gameHistory.length - index}
                  lastRound={index ===  lastRoundIndex}
                />
            ))}
        </div>
      </div>
    </div>
  );
};

export default HistoryMoves;
