import React from 'react';
import { selectOption } from 'shared/components/Select/Select';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  list: selectOption[];
  onClick: (option: selectOption) => void;
  active?: string | number;
  position: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
}

const Dropdown = ({ open, list, onClick, position, active }: Props) => {
  const { t } = useTranslation();
  return (
    <AnimatedTransition show={open} asFragment>
      <div
        className="rounded-[4px] border border-border bg-blue-menu absolute z-50 w-[196px]"
        style={{ ...position }}
      >
        <ul className=" flex flex-col items-center text-[16px] cursor-pointer">
          {list?.length > 0 &&
            list.map((item, index) => (
              <li
                className={`w-[174px] py-[12px] ${
                  index !== list.length - 1 ? 'border-b border-border' : ''
                }
                ${item.value == active ? 'text-yellow-text' : 'text-white'}
                `}
                key={index}
                onClick={() => onClick(item)}
              >
                {t(item.label)}
              </li>
            ))}
        </ul>
      </div>
    </AnimatedTransition>
  );
};

export default Dropdown;
